import { navigate } from 'gatsby'
import React from 'react'
import { useQuery } from '@apollo/client'
import { filter as filterLodash } from 'lodash'
import { capitalize } from 'lodash'
import { CacheUser, OptionType, PurchaseRequest, ResponseType, TableColumnType } from '@types'
import { purchaseRequest as graphqlPurchaseRequest, user as graphqlUser } from '@graphql'
import { StatusIcon, Button } from '@elements'
import { AccountDetailsLayout, AccountLayout, MobileAccountDetailsLayout } from '@layouts'
import { get12HourFromDate, getGolfClubInfoByCategory } from '@utils'
import { useWindowSize } from '@hooks'
import './details.scss'

interface AccountMyOfferBookDetailsProps {
  purchaseRequestId: string
}

const formatDate = (date: string | Date) => {
  const d = new Date(date)

  const day = d.getDate()
  const month = d.getMonth() + 1
  const year = d.getFullYear()

  return `${month}/${day}/${year}`
}

const renderStatus = (status: string) => {
  switch (status) {
    case 'OPEN':
      return <StatusIcon status={'Active'} />
    case 'CLOSED':
      return <StatusIcon status={'Purchased'} />
    case 'EXPIRED':
      return <StatusIcon status={'Expired'} />
  }
}

const renderMobileContent = (purchaseRequest: PurchaseRequest) => {
  const { status, golfClubType, expiresAt, insertedAt, golfEquipmentType } = purchaseRequest
  const golfModel = golfClubType?.golfClubModel || golfEquipmentType?.golfEquipmentModel
  const golfType = golfClubType || golfEquipmentType
  const formattedInsertedDate = `${formatDate(insertedAt)} ${get12HourFromDate(new Date(insertedAt))}`
  const formattedExpiredDate = expiresAt ? `${formatDate(expiresAt)}` : 'Good ‘Til Cancelled'
  const purchaseRequestOption = getGolfClubInfoByCategory(golfModel?.category)
  const data = [
    {
      label: 'Brand',
      value: golfModel?.brand?.title || '-'
    },
    {
      label: 'Category',
      value: golfModel?.category?.title || '-'
    },
    {
      label: 'Release year',
      value: (golfModel?.releaseYear || '-').toString()
    },
    {
      label: 'Flex',
      value: golfClubType?.flex || null
    },
    {
      label: 'Dexterity',
      value: golfClubType?.dexterity || null
    },
    {
      label: 'Condition',
      value: golfType?.condition || '-'
    },
    {
      label: purchaseRequestOption.title,
      value: golfClubType?.option ? golfClubType.option + purchaseRequestOption.options.symbol : false
    }
  ]

  const filteredData = filterLodash(data, o => o.value) as OptionType[]

  const dateData = [
    {
      label: 'Offer Date',
      value: formattedInsertedDate
    },
    {
      label: 'Expire Date',
      value: formattedExpiredDate
    }
  ]

  return (
    <MobileAccountDetailsLayout
      status={status}
      title={golfModel.title || ''}
      avatar={golfModel?.avatar || ''}
      editLink={`/account/my-offer-book/${purchaseRequest.id}/edit-details`}
      titleLink={`/product/${golfModel?.slug}`}
      data={filteredData}
      greyData={dateData}
      backLink={'/account/my-offer-book'}
      backLinkTitle={'My Offer Book'}
    >
      <div className={'account-my-offer-book-details-mobile-footer'}>
        <div className={'account-my-offer-book-details-mobile-param-row'}>
          <div className={'uppercase'}>
            <strong>Your price</strong>
          </div>
          <div>
            <strong>${purchaseRequest.itemPrice}</strong>
          </div>
        </div>
        <div className={'account-my-offer-book-details-mobile-param-row'}>
          <div className={'uppercase'}>
            <strong>Status</strong>
          </div>
          {renderStatus(status)}
        </div>
      </div>
    </MobileAccountDetailsLayout>
  )
}

const renderDesktopContent = (purchaseRequest: PurchaseRequest) => {
  const { status, golfClubType, updatedAt, expiresAt, itemPrice, golfEquipmentType } = purchaseRequest
  const golfClubModel = golfClubType?.golfClubModel || golfEquipmentType?.golfEquipmentModel
  const golfType = golfClubType || golfEquipmentType
  const isClosedRequest = status === 'CLOSED'

  const columns: TableColumnType[] = [
    {
      title: 'Item',
      key: 'item',
      render() {
        const title = golfClubModel?.title
        return <>{title || '-'}</>
      }
    },
    {
      title: 'Category',
      key: 'category',
      render() {
        const title = golfClubModel?.category.title
        return <>{title || '-'}</>
      }
    },
    {
      title: 'Brand',
      key: 'brand',
      render() {
        const title = golfClubModel?.brand.title
        return <>{title || '-'}</>
      }
    },
    {
      title: 'Release Time',
      key: 'releaseYear',
      render() {
        const releaseYear = golfClubModel?.releaseYear
        return <>{releaseYear || '-'}</>
      }
    },
    {
      title: 'Flex',
      key: 'golfClubType.flex',
      isNotGolfEquipment: true,
      render(flex: string) {
        return <>{capitalize(flex)}</>
      }
    },
    {
      title: 'Dexterity',
      key: 'golfClubType.dexterity',
      isNotGolfEquipment: true,
      render(dexterity: string) {
        return <>{capitalize(dexterity)}</>
      }
    },
    {
      title: 'Condition',
      key: 'golfClubType.condition',
      render() {
        const title = golfType?.condition
        return <>{title || '-'}</>
      }
    },
    {
      title: 'Option',
      key: 'golfClubType.option',
      render() {
        const option = golfType.option
        return <>{option || '-'}</>
      }
    }
  ]

  const filteredData = filterLodash(columns, o => !o.isNotGolfEquipment)

  return (
    <AccountDetailsLayout
      title={'My Offer Book'}
      details={{
        title: 'Item',
        text: golfClubModel?.title + ' ' + golfClubModel?.brand.title,
        link: `/product/${golfClubModel?.slug}`,
        date: updatedAt,
        expired: expiresAt
      }}
      backUrl={'/account/my-offer-book'}
      renderHeaderButtons={() => {
        return (
          <>
            {!isClosedRequest && (
              <Button
                type={'roundWithHoverBg'}
                onClick={() => navigate(`/account/my-offer-book/${purchaseRequest.id}/edit-details`)}
              >
                Edit
              </Button>
            )}
          </>
        )
      }}
      avatar={golfClubModel?.avatar}
      columns={filteredData}
      data={purchaseRequest}
      loading={false}
    >
      <div className={'account-my-offer-book-details-footer'}>
        <div className={'account-my-offer-book-details-footer-item__first'}>
          <div className={'uppercase'}>
            <strong>YOUR PRICE</strong>
          </div>
          <div>
            <strong>$ {itemPrice}</strong>
          </div>
        </div>
        <div className={'account-my-offer-book-details-footer-item__second'}>
          <div className={'uppercase'}>
            <strong>STATUS</strong>
          </div>
          <div>{renderStatus(status)}</div>
        </div>
      </div>
    </AccountDetailsLayout>
  )
}

const AccountMyOfferBookDetails: React.FC<AccountMyOfferBookDetailsProps> = props => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { data: userData, loading: userLoading } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const user = userData && userData.res

  if (!userLoading && !user) navigate('/home/')
  if (!user) return null

  const { purchaseRequestId } = props

  const { data: purchaseRequestData, loading: purchaseRequestLoading } = useQuery<ResponseType<PurchaseRequest>>(
    graphqlPurchaseRequest.FetchPurchaseRequest,
    {
      variables: { id: purchaseRequestId }
    }
  )

  const backUrl = '/account/my-offer-book'
  const purchaseRequest = purchaseRequestData?.res

  if (!purchaseRequestLoading && !purchaseRequest) navigate(backUrl)
  if (!purchaseRequest) return null

  return (
    <AccountLayout pageClassName={'my-offer-book-details'} hideLayout={isMobileScreen}>
      {isMobileScreen ? renderMobileContent(purchaseRequest) : renderDesktopContent(purchaseRequest)}
    </AccountLayout>
  )
}

export default AccountMyOfferBookDetails
